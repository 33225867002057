.footer {
    display: flex;
    height: 20vh;
    margin-top: 10vh;
    justify-content: center;
    background-color: #869b84;
    color: white;
    font-family: 'CustomFont', serif;
    align-items: center;
    text-align: center;
}

.footer a {
    color: white;
    text-decoration: underline;
    margin: 0 10px;
    font-weight: 100;
}