.homepage {
    background-color: #eaeaea;
    height: 100vh;
    overflow-x: hidden;
}

.title-container {
    display: flex;
    width: 100%;
}

.title {
    display: flex;
    font-family: 'CustomFont2', serif;
    font-size: 17rem;
    color: #55755b;
    font-weight: 500;
    letter-spacing: -13px;
    justify-content: center;
    align-items: center;
    white-space: nowrap; 
    animation: fadeIn 2s forwards, slide 20s linear infinite;
    margin-right: 2rem;
    margin-top: 1rem;
}

.third-title {
    margin-top: -7rem;
    animation: fadeIn-last 2s forwards, slide 20s linear infinite;
}

.title-right {
    display: flex;
    font-family: 'CustomFont2', serif;
    font-size: 17rem;
    color: #55755b;
    font-weight: 500;
    letter-spacing: -13px;
    justify-content: center;
    align-items: center;
    white-space: nowrap; 
    animation: fadeIn-right 2s forwards, slide-right 15s linear infinite;
    margin-right: 2rem;
    opacity: 0.5;
    margin-top: -6rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} 

@keyframes fadeIn-right {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
} 

@keyframes fadeIn-last {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
} 


@keyframes slide {
    0% {
        transform: translateX(100);
    }
    50% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes slide-right {
    0% {
        transform: translateX(-150%);
    }
    50% {
        transform: translateX(-75%);
    }
    100% {
        transform: translateX(0%);
    }
}

.name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
  }
  
  .first-name, .last-name {
    font-size: 3rem;
    position: absolute;
    opacity: 0;
  }
  
  .first-name {
    left: 0;
  }
  
  .last-name {
    right: 0;
  }
  
  .name-switch {
    position: absolute;
    text-align: center;
    font-size: 2rem;
  }
  

.animation-title {
    font-family: 'CustomFont', serif;
    font-size: 10rem;
    color: #55755b;
    color: #55755b;
    font-weight: 500;
    justify-content: center;
    display: flex;
}

.firstName {
    font-size: 2rem;
}

.lastName {
    font-size: 2rem;
}

.isHomeLoaded .firstName, .isHomeLoaded .lastName{
    display: none;
}

.typewriter {
    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 2.5rem;
    color: rgb(160, 160, 160);
    margin-top: 0em;
    letter-spacing: .12em;
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    animation: fadeIn 1.5s forwards;
}

.name-tag-container {
    perspective: 1000px; 
    margin-top: 12em;
    animation: tiltAnimation 3s ease-in-out infinite; 
}

.name-tag-card {
    width: 250px; 
    height: 100px; 
    transform-style: preserve-3d;
    transition: transform 0.6s ease;
    display: inline-block;
}

.name-tag-container:hover .name-tag-card {
    transform: rotateY(180deg) scale(1) scaleY(1.3); 
}

.name-tag-container:hover  {
    animation: none;
}

@keyframes tiltAnimation {
    0% {
        transform: rotateZ(-5deg);
    }
    50% {
        transform: rotateZ(5deg); 
    }
    100% {
        transform: rotateZ(-5deg); 
    }
}

.name-tag-front,
.name-tag-back {
    position: absolute;
    scale: 2.2;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    align-items: center;
    border-radius: 5px;
}

.name-tag-front p {
    font-family: 'NameTagFont';
    margin: 0;
    padding: 5px;
    font-size: 3rem;
    font-weight: bold;
}

.name-tag-front {
    display: inline-block;
    width: 190px;
    background-color: #ffffff;
    color: #7d9d74;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
    animation: rotateBackAndForth 3s ease-in-out infinite;
    transition: transform 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border: 2px solid #7d9d74;
}

.name-tag-header {
    background-color: #7d9d74;
    color: #e6e6e6; 
    font-size: 0.9rem;
    font-weight: bold;
    padding: 5px;
    width: 95%;
}

.name-tag-back {
    background-color: #fff;
    transform: rotateY(180deg) scaleY(1.3); 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.name-tag-back img {
    width: 100%; 
    height: 100%;
    object-fit: fill; 
    border-radius: 5px;
}

.about-me {
    opacity: 0;
    color: #55755b;
    margin-left: 10rem;
    margin-right: 10rem;
    margin-top: 2em;
    animation: fadeIn 1.5s forwards;
    transform: translateY(30px); 
}

.about-me p {
    font-family: CustomFont, serif;
    font-size: 1.2rem;
    line-height: 1.5;
    color: rgb(93, 93, 93);

}

.skills {
    display: flex; 
}

.hover-list {
    list-style-type: none;
    padding: 0;
    width: 500px;
    margin: 10px;
}

.hover-list li {
    background-color: #f4f4f4;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.hover-list li:hover {
    background-color: #55755b;
    font-weight: bold;
    color: white;
    transform: scale(1.05);
}

.fun-fact-box {
    position: relative;
    border: 1px solid #55755b;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    background-color: #f4f4f4;
    transition: all 0.3s ease;
}

.initial-text {
    font-size: 1.2rem;
    color: #55755b !important;
    margin: 0;
}

.fun-fact-text {
    display: none; 
    font-size: 1.5rem;
    color: #55755b;
    margin: 0;
    transition: all 0.3s ease;
}

.fun-fact-box:hover {
    background-color: #55755b;
    color: white;
    transform: scale(1.1);
}

.fun-fact-box:hover .initial-text {
    display: none; 
}

.fun-fact-box:hover .fun-fact-text {
    display: block;
    color: #fff;
}

.experience {
    color: #55755b;
    margin-left: 10rem;
    margin-right: 10rem;
    margin-top: 8rem;
    animation: fadeIn 1.5s forwards;
    transform: translateY(30px); 
}

.experience p {
    font-family: CustomFont, serif;
    font-size: 1.2rem;
    line-height: 1.5;
    color: rgb(93, 93, 93);

}

.tab-content {
    text-align: center;
}

.tab-content h3 {
    color: #7d8c81;
    font-weight: 400;
}

.hobbies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.hobby-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hobby-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.hobby-icon {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.hobby-name {
    font-size: 1.2rem;
    font-weight: 600;
}


@media (max-width: 768px) {
    .title, .title-right {
        font-size: 10rem;
        line-height: 30vh;
    }

    .typewriter {
        font-size: 1.3rem;
    }

    .hover-list li {
        width: 100%;
        max-width: none;
    }

    .name-tag-front,
    .name-tag-back {
        scale: 1.7;
        margin-top: -2em;
    }

    .about-me {
        scale: 0.8;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: -8em;
    }

    .skills {
        flex-direction: column;
        align-items: center;
        margin-top: 4em;
    }

    .hover-list {
        width: 20em;
        text-align: center;
        margin-top: -1.5em;
    }

    .fun-fact-box {
        margin-top: 2em;
        margin-bottom: -12em;
    }

    .name-tag-card {
        scale: 0.7;
    }

    .experience {
        scale: 0.8;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: -2em;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; 
        height: 800px; 

    }
    .tab-content-container {
        flex-grow: 1;
        overflow-y: auto; 

    }

    .arrow-header {
        display: flex;
        justify-content: space-between;
        align-items: center; 
        width: 100%; 
    }
    
    .arrow {
        color: green; 
        margin: 0 10px; 
    }

    
}