#navbar {
    background-color: rgba(251, 251, 251, 0.575); 
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 10; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
}

#navbar ul {
    list-style: none;
    display: flex; 
    justify-content: center;
    padding: 0;
    margin: 0;
}

#navbar p, a {
    margin: 15px 15px;
    text-decoration: none; 
    color: #55755b;
    font-weight: bold;
    transition: color 0.3s; 
    cursor: pointer;
}

#navbar p:hover, a:hover {
    color: #bdbdbd; 
}

.icons {
    display: flex; 
    justify-content: end;
    margin-top: -33px;
    margin-bottom: 10px;
    margin-right: 20px;
}

.icons a {
    margin: 0 6px; 
}

@media (max-width: 768px) {
    #navbar ul {
        flex-direction: column;
    }

    .nav-open {
        background-color: rgba(251, 251, 251, 0.946) !important; 
    }

    .icons {
        margin-top: -35px;
        padding-bottom: 6px;
    }

    .burger {
        scale: 0.8;
        color: #55755b;
        margin-top: 8px;
        margin-left: -15px;
        padding-bottom: 0px;
    }
}
